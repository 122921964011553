import Compare from "./compare";
import {storageAvailable} from '../misc/helpers';

const CompareTray = {
	"parent": {},
	"lang": {},
	"template" : undefined,
	"settings": {
		"showing": false,
		"max-items": 5
	},
	"state": {
		"productList": [],
		"open": false
	},
	"init": function (lang) {
		CompareTray.lang = lang;
		CompareTray.bindEvents();
		CompareTray.getCompareTray();
		CompareTray.render();
	},

	"setURL": function (items) {
		window.location.href = "/#compare?" + items.join("--");
	},

	"bindEvents": function(){

		$('#app').on('click','[data-compare-toggle]', function(){
			$('#compare-tray').toggleClass('show');
			$('body').toggleClass('compare-tray-open');
			CompareTray.state.open = $('#compare-tray').hasClass('show');

			localStorage.setItem('CompareTrayOpen', $('#compare-tray').hasClass('show'));
			if (storageAvailable("localStorage")){
				localStorage.setItem('CompareTrayState', JSON.stringify(CompareTray.state));
			}
		});

		$('#app').on('click','[data-cancel-compare]', function(){
			CompareTray.state.productList = [];
			CompareTray.updateCompareTray();
		});
		

		/* compare add button handler */
		$('#app').on('click', '[data-addtocomparebutton]', function(){
			if (CompareTray.isFull()){
				$('[data-addtocomparebutton]').addClass('disabled');
			} else {

				$(this).addClass('selected');
				var productId = $(this).attr('data-item-id');
				var productName = $(this).attr('data-item-name');
				if (CompareTray.validateProduct(productId)) {
					CompareTray.state.productList.push({
						"id":productId,
						"name": productName
					});
					CompareTray.updateCompareTray();
					
				}
				if (CompareTray.isFull()){
					$('[data-addtocomparebutton]').addClass('disabled');
				}
			}
		});

		$('#app').on('click', '[data-addtocomparebutton].disabled', function(){
			$('[data-compare-max]').removeClass('blink').addClass('blink');
			setTimeout(function(){
				$('[data-compare-max]').removeClass('blink');
			}, 1000);
		});


		/* compare remove button handler */
		$('#app').on('click', '[data-removecomparebutton]', function(){
			$(this).removeClass('selected');
			var productId = $(this).attr('data-item-id');
			CompareTray.removeCompareItem(productId);
			$('[data-addtocomparebutton]').removeClass('disabled');
		});


		$('#app').on('click', '[data-compare-button]', function () {
			CompareTray.setURL(CompareTray.getCompareBasketList() );
		});


	},
	
	"removeCompareItem": function(itemId){
		for (var v = 0; v < CompareTray.state.productList.length; v++){
				
			if (CompareTray.state.productList[v].id == itemId){
				CompareTray.state.productList.splice(v,1);
				CompareTray.updateCompareTray();
				return;
			}
		}
	},
	"updateCompareTray": function(){
		if (storageAvailable("localStorage")){
			localStorage.setItem('CompareTrayState', JSON.stringify(CompareTray.state));
			CompareTray.render();
			$('#app').trigger('CompareUpdated', { items: CompareTray.getCompareBasketList() } );
			
		}
	},
	"getCompareTray": function(){
		if (storageAvailable("localStorage")){
			var CompareTrayState = localStorage.getItem('CompareTrayState');
			if (CompareTrayState !== null) {
				CompareTray.state = JSON.parse(localStorage.getItem('CompareTrayState'));
			}	
		}

	},
	"listContains": function(id){ /* check if basket contains id */
		for (var v = 0; v < CompareTray.state.productList.length; v++){
			if (CompareTray.state.productList[v].id == id){
				return true;
			}
		}

		return false;
	},
	"isFull": function(){
		return CompareTray.state.productList.length >= CompareTray.settings["max-items"];
	},
	"validateProduct": function(id) { /* check if basket already contains id and that basket isn't full*/
		if (CompareTray.state.productList.length < CompareTray.settings["max-items"]){
			return !CompareTray.listContains(id);
		} 
		
		return false;
		
	},

	"loadTemplate": function (template, callback) {
		if (typeof CompareTray.template !== 'undefined') {
			callback(CompareTray.template);
			return;
		} else {

			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function () {
				if (this.readyState == 4 && this.status == 200) {
					CompareTray.template = this.responseText;
					callback(this.responseText);
				}
			}
			xhttp.open("GET", template, true);
			xhttp.send();
			return;
		}
	},

	"getCompareBasketList": function(){
		var ids= [];
		for (var v = 0; v < CompareTray.state.productList.length; v++){
			ids.push(CompareTray.state.productList[v].id)
		}
		return ids;
	},

	"render": function(){
		if (CompareTray.state.productList.length > 0) {
			$('body').addClass('show-compare-tray');
		} else {
			$('body').removeClass('show-compare-tray');
		}
		if (typeof window.api !== "undefined"){
			CompareTray.loadTemplate(window.api.template_path + "compare-bar.html", function (template) {
				$('#app-compare-tray').html(ejs.render(template, {...CompareTray.lang, ...CompareTray.state}));
			});
		}
	}
}

export default CompareTray;