const UrlParser = {
	
	"parseURL": function(){

		function deserialiseURLParam(urlparams) {
			var params = Object.fromEntries(new URLSearchParams(urlparams));
			var keys = Object.keys(params);
			for (var v = 0; v < keys.length; v++){
				if (keys[v].indexOf('::') > -1){
					var splitKey = keys[v].split("::");
					if (typeof params[splitKey[0]] == "undefined"){
						params[splitKey[0]] = {}
					} 
					if (params[keys[v]].indexOf(",") > -1){
						params[keys[v]] = params[keys[v]].split(",");
					}
					params[splitKey[0]][splitKey[1]] = params[keys[v]];
					delete params[keys[v]];
				} 
			}
			return params;
		}

		var url = window.location.hash.substring(1).split("?");
		var params = deserialiseURLParam(url[1]);
	//	console.log("P",params);
		return [url[0], params];
	},

	"setURL": function(route, params){

		function serialiseURLParam(urlparams, param, key ){
			//add a parameter to a URLSearchParam.
			//if its an object, split it into multiple - so filters: { test : test2 } becomes filters::test=test2
			//this won't work for items multiple objects deep - so avoid those!
		
			if (typeof param == "object" && param !== null){
				var keys = Object.keys(param);

				for (var v = 0; v < keys.length; v++){
					urlparams.append(key+"::"+keys[v], param[keys[v]]);
				} 
			} else {
				urlparams.append(key,param);
			}
			
			return urlparams;
		}

		var location = '#' + route;
		if (params == '' || typeof params=="undefined") {
			window.location.hash = '';
		} else {
			var URLParams = new URLSearchParams();
			var keys = Object.keys(params);
			for (var v = 0; v < keys.length; v++){
				serialiseURLParam(URLParams, params[keys[v]], keys[v]);
			}
			
			try {
				window.location.hash = location + '?' + URLParams.toString();
			} catch (e){
				window.location.hash = location;
			}
		}
	}
}

export default UrlParser;