import URLParser from '../misc/url-parser';

const Home = {
	"parent": {},
	"settings": {},
	"state": {},
	"items": [],
	"init": function(MaterialFinder){
		Home.parent = MaterialFinder;
		Home.bindEvents();

		Home.items = $('.family-item').each((index, item)=>{
			Home.items.push(item);
		});

		window.addEventListener('load', function(){
			if (this.document.querySelector('.glide') !== null) {
				const glide = new Glide('.glide', {
					type: 'carousel',
					autoplay: 5000,
					gap:10,
					dragThreshold: false

				});
				
				glide.on(['mount.after'], function() {
					document.querySelector('.banner-container').classList.toggle('out')
				});
				
				glide.mount()
			}
		});
		
	},
	"bindEvents": function(){
		$('#app').on('click', '[data-family-toggle]', function(){
			var state = $(this).parents('.family-item').hasClass('open');
			$('#app-home').find('.family-item').removeClass('open');
			if (!state){
				$(this).parents('.family-item').addClass('open');
			}
		});

		$('#app').on('select2:select', '[data-family-sort]', function(){
			var itemList = [];

			function asc_sort(a, b){
				return ($(b).attr('data-family-title') < $(a).attr('data-family-title')) ? 1 : -1;    
			}
			
			// decending sort
			function dec_sort(a, b){
				return ($(b).attr('data-family-title') > $(a).attr('data-family-title')) ? 1 : -1;    
			}

			var direction = $(this).val();
	
			Home.items.each((index, item)=>{
				itemList.push(item);
			});

			itemList.sort(function(a,b){	
				var sort = (direction == "ASC")? asc_sort(a,b) : dec_sort(a,b);
				return sort;
			});

			$('[data-family-list]').html('');

			$(itemList).each((index, item)=>{
				$('[data-family-list]').append(item);
			});

			
		});


	},

	"render": function(){
		//reset filters
		URLParser.setURL('', '');
		$('#home-page-search').val('');
		Home.parent.SearchPage.state = {
			"searchTerm": "",
			"filters": {}
		};
		Home.parent.FilterList.render();
		Home.parent.SearchBar.render();
		
		$('[data-family-sort]').select2({
			minimumResultsForSearch: -1
		});	
		
	}
}

export default Home;