const SearchBar = {
	"parent": {},
	"settings": {
		"searchbox_timeout":500,
		"searchbox_minchars": 3,
		"searchbox_handle": undefined
	},
	"init": function(MaterialFinder){
		SearchBar.parent = MaterialFinder;
		SearchBar.bindEvents();
	},
	"bindEvents": function(){
		var SearchPage = SearchBar.parent.SearchPage;

		/* event fired on clicking search (do search!) */
		$('#app').on('click', '[data-searchbutton]', function(){
			var searchBoxId = document.getElementById($(this).attr('data-searchbox-id'));
			SearchPage.state.searchTerm = $(searchBoxId).val();
			$('#app').trigger("SearchKeywordChange");
		});
		
		$('#app').on('submit', '[data-search-form]', function(e){
			e.preventDefault();
		});
	},

	"render": function(){
		var SearchPage = SearchBar.parent.SearchPage;
		
		if (typeof window.api !== "undefined"){
			SearchBar.parent.loadTemplate("SearchBar", window.api.template_path + "search-bar.html", function(template){
				$('#app-search-bar').html(ejs.render(template, { ...SearchBar.parent.lang, ...SearchPage.state }));
				SearchBar.parent.initAutocompleteSearch();
			});
			
			
		}
		
	}
}

export default SearchBar;