
import noUiSlider from 'noUiSlider';
import 'nouislider/dist/nouislider.css';
import {storageAvailable} from '../misc/helpers';
const FilterList = {
	"settings": {
		"activeFilter": undefined,
		"haschanged": false,
		"filterFilter": {
			"industries": null,
			"materialFeatures": null,
			"properties": null,
			"polymerFamilies": null,
			"fillers": null,
			"brands": null,
			"sustainables": null	
		}
	},
	"parent": {},

	"init": function(MaterialFinder){
		FilterList.parent = MaterialFinder;
		FilterList.bindEvents();
	},

	"bindEvents": function(){
		var SearchPage = FilterList.parent.SearchPage;

		$('#app').on('click','[data-range-toggle]', function(e){
			$(this).parent('.label').toggleClass('show');
			$('#app').trigger('filtersUpdated');
		});

		/* event fired on clicking select/deselect all */
		$('#app').on('click','[data-bind="filter-toggle-all"]', function(e){
			var section = $(this).parents(".filter-list-section");
			var deselect = $(section).find('[type="checkbox"]').filter(':checked').length > 0;
		
			$(section).find('[type="checkbox"]').each(function(index,el){
				if (!deselect){
					$(el).prop('checked',true);
					
				} else {
					$(el).prop('checked',false);
				}
			});

			if (deselect) {
				$(this).removeClass('deselect');
			} else {
				$(this).addClass('deselect');
			}
			$('#app').trigger('filtersUpdated');
			e.stopPropagation();
		});

		$('#app').on('click','[data-clear-all-filters]', function(){
			SearchPage.settings.activeFilter = undefined;
			if (storageAvailable("sessionStorage")){
				sessionStorage.setItem('open_filters',[]);
				
			}
			FilterList.clearAllFilters();
		});

		$('#app').on('click', '[data-toggle]', function (e) {
			$(this).parents('.filter-accordion').toggleClass('open');
		});

		$('#app').on('keyup','[data-filter-list-filter]', function(e){
			const category = this.getAttribute('data-filter-list-filter');
			FilterList.filterFilterList(category, this.value, this);
		});

		$('#app').on('click', '[data-remove-productId]', function (e) {
			SearchPage.state.productId = null;
			SearchPage.state.productName = null;
			$('#app').trigger("SearchParamsUpdated", true);
		});

		$('#app').on('click', '[data-remove-productFamilyId]', function (e) {
			SearchPage.state.productFamilyId = null;
			SearchPage.state.productFamilyName = null;
			$('#app').trigger("SearchParamsUpdated", true);
		});
		$('#app').on('click', '[data-remove-searchterm]', function(e){
			var keyword = $(this).attr('data-keyword');
			var newKeyword = SearchPage.state.searchTerm.replace(keyword, '').trim();
			newKeyword = newKeyword.replace(/ +(?= )/g,'');
			SearchPage.state.searchTerm = newKeyword;
			$('#app').trigger("SearchParamsUpdated", true);
		});

		$('#app').on('click', '[data-bind="filter-title"]', function(e){
			$(this).parents('.filter').toggleClass('open');
			if ($(this).parents('.filter').hasClass('active-open')) {
				$('#filters .filter').removeClass('active-open');
			} else {
				$('#filters .filter').removeClass('active-open');
				$(this).parents('.filter').toggleClass('active-open');
			}
			SearchPage.settings.activeFilter = $(this).parents('.filter').attr('id');
			if (storageAvailable("sessionStorage")){
				sessionStorage.setItem('open_filters',FilterList.getOpenFilters());
			}
			e.stopPropagation();
		});

		$('#app').on('keydown', '[data-bind="filter-title"]', function(e){
			if (e.keyCode === 13) {
			  e.preventDefault();
			  $(this).trigger('click');
			}
		});

		$('#app').on('click', '.filter-list', function(e){
			e.stopPropagation();
		});

		$('body').on('click', function(){
			SearchPage.settings.activeFilter = undefined;
			$('#app-filters #filters .filter').removeClass('active-open');
			sessionStorage.setItem('open_filters',FilterList.getOpenFilters());
		});

		/* event fired on clicking individual filter */
		$('#app').on('change','[data-filter],[data-range-filter]', function(e){ 	
			e.preventDefault();
			var $parent = $(this).parents(".filter-list-section"),
				$selectAll = $parent.find('.select-all');

			if ($parent.length && $selectAll.length){
				var numSelected = $parent.find('input:checked').length;
			
				if (numSelected > 0){
					$parent.find('.select-all').addClass('deselect');
				} else {
					$parent.find('.select-all').removeClass('deselect');
				}
			}
			$('#app').trigger('filtersUpdated');
		});

		$('#app').on('filtersUpdated', function(){
			$('[data-apply-filters]').addClass('updated');
			$('[data-apply-filters]').prop('disabled',false);
			
		});

		$('#app').on('click', '[data-apply-filters]', function(){
			SearchPage.state.filters = FilterList.getFilters();	
			$('#app-filters #filters .filter').removeClass('active-open');
			sessionStorage.setItem('open_filters',[]);
			$('#app').trigger("SearchParamsUpdated", true);
		});

		$('#app').on('click', '[data-remove-filter]', function(){
			
			var type=$(this).attr('data-filter');

			if (type !== "properties"){
				delete SearchPage.state.filters[type]
			} else {
				for (var key in SearchPage.state.filters) {
					if (key.startsWith('properties_')) {
						delete SearchPage.state.filters[key];
					};
					
				}
			}
			$('#app').trigger("SearchParamsUpdated", true);
		});

	},

	"getOpenFilters": function(){
		var open_filters = [];
		$('#app-filters .filter').each(function(i, el){
			if ($(el).hasClass('open')) {
				open_filters.push($(el).attr('id'));
			}
		});
		return open_filters;
	},

	"getFilters": function(){/* returns a list of all selected filters */
		var filters = {};

		$('[data-filter]').each(function(){
			if ($(this).is(':checked')){
				var name = $(this).attr("name");
				var value = $(this).val();

				if (typeof filters[name] == "undefined") {
					filters[name] = [value];
				} else {
					filters[name].push(value);
				}
			}	
		});	

		$('[data-range-filter]').each(function(i,el){
			if ($(this).parents(".label").hasClass('show')){
				var name = $(el).attr("name");
				var value = 0;

				if (typeof $(el)[0].noUiSlider !== 'undefined'){
					value = $(el)[0].noUiSlider.get();
				}
			
				filters[name] = value;
			}
		});

		return filters;
	},

	"clearAllFilters": function(){		
		$('#app-filters .filter').removeClass('open');
		
		$('#app').trigger("SearchParamsCleared");
	},

	"hasFilters": function(){
		var SearchPage = FilterList.parent.SearchPage;
		
		for (var filter in SearchPage.state.filters) {
			if (filter.length > 0) {
				return true;
			}
		}
		return false;
	},

	"filterFilterList": function(category, keyword, el){
		console.log("category", FilterList.settings.filterFilter);
		FilterList.settings.filterFilter[category] = keyword;
		const filterItems = el.parentNode.parentNode.querySelector('.filter-list-options').querySelectorAll('label');

		filterItems.forEach(filterItem => {
			const title = filterItem.querySelector('span').getAttribute('title');
			if (title.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
				filterItem.style.display= 'block'
			} else {
				filterItem.style.display= 'none'
			}
		})

	},

	"getFilterCount": function(items){
		if (items == undefined){
			return 0;
		}
		return Array.isArray(items)?items.length:1; 
	},

	"getPropertyFilterCount": function(items){
		var key, count = 0;
		
		for (key in items) {
			if (!key.startsWith('properties_')) continue;
			count += 1;
		}
		return count;
	},

	"render": function(){
		var SearchPage = FilterList.parent.SearchPage;
		var MaterialFinder = FilterList.parent;
		var isHomepage = !document.querySelector('#app').classList.contains('display-search-results');

		if (typeof window.api !== "undefined"){
			FilterList.parent.loadTemplate("filters", window.api.template_path + "filters.html", function(template){
				$('#app-filters').html(ejs.render(template, { 
					getFilterCount: FilterList.getFilterCount, 
					hasFilters: FilterList.hasFilters, 
					isHomepage: isHomepage,
					getPropertyFilterCount: FilterList.getPropertyFilterCount, 
					...MaterialFinder.lang, 
					...{ searchTerm: SearchPage.state.searchTerm, filters: window.filtersList, filterFilterList: FilterList.settings.filterFilter, activeFilters: SearchPage.state.filters },
					...{
						productName: SearchPage.state.productName,
						productId: SearchPage.state.productId,
						productFamilyId: SearchPage.state.productFamilyId,
						productFamilyName: SearchPage.state.productFamilyName
					}
				}));
				FilterList.checkFilters();
			});
		}
	},

	"checkFilters": function(){ /* handle selecting filters */
		var SearchPage = FilterList.parent.SearchPage;

		for (var filter in SearchPage.state.filters) {
			var list_of_filters = SearchPage.state.filters[filter];
			if (Array.isArray(list_of_filters)){
				for (var v = 0; v < list_of_filters.length; v++){
					var check = $('#filters').find('input[name="'+filter+'"][value="'+list_of_filters[v]+'"]');
					check.prop("checked", true);
					check.parents('.filter-list-section').find('[data-bind="filter-toggle-all"]').addClass('deselect');	
				}
			} else {
				var check = $('#filters').find('input[ name="'+filter+'"][value="'+SearchPage.state.filters[filter]+'"]').prop("checked", true);
				check.parents('.filter-list-section').find('[data-bind="filter-toggle-all"]').addClass('deselect');		
			}
		}

		$("[data-range-filter]").each(function(i,el){
			var name = $(el).attr('name');
			var values = (SearchPage.state.filters !== undefined)?SearchPage.state.filters[name]:undefined;
			
			if (typeof values == 'undefined'){
				values = [Number($(el).attr('data-range-default-min')),Number($(el).attr('data-range-default-max'))];
			} else {
				$(el).parents('.label').addClass("show");
			}

			noUiSlider.create(el, {
				start: [values[0], values[1]],
				connect: true,
				tooltips:  true,
				range: {
					'min':  Number( $(el).attr('data-range-filter-min') ),
					'max':  Number($(el).attr('data-range-filter-max') )
				},
				format: {
					to: function(value) {
						if (value > 0 && value < 0.01) {
							return value.toExponential(2)
						} else if (value > 100) {
							return value.toFixed(0);
						} else {
							return value.toFixed(2);
						}
					},
					from: Number
				}
			});

			el.noUiSlider.on('change', function (values, handle) {
				SearchPage.state.filters = FilterList.getFilters();	
				SearchPage.state.open_filters = FilterList.getOpenFilters();	
				$('#app').trigger('filtersUpdated');
			});
			
		});

		
		if (false && storageAvailable("sessionStorage")){
			const open_filters = sessionStorage.getItem('open_filters');
			if (open_filters !== null){
				const split_filters = open_filters.split(",");
			
				for (var open_filter in split_filters) {
					if (open_filter !== '' && document.getElementById(split_filters[open_filter]) !== null) {
						document.getElementById(split_filters[open_filter]).classList.add('open');
					}
					
				}
			} 
		
		}

		if (false&&SearchPage.settings.activeFilter !== '' && document.getElementById(SearchPage.settings.activeFilter) !== null) {
			document.getElementById(SearchPage.settings.activeFilter).classList.add('active-open');
		}

		

	}
}

export default FilterList;