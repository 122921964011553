import api from '../api/api';
import ProductComparison from '../misc/product-comparison.js';
import CurveDataItem from '../misc/curve-data.js';
import Curvedata from '../misc/curve-data.js';

const Compare = {
	"parent": {},
	"settings": {
		"showComparableOnly": false,
		"chart": {}
	},	
	"state": {
		"productList": [],
		"curveData": {},
		"activeChart": {}
	},
	"apiResponse": {},

	"init": function(MaterialFinder){
		Compare.parent = MaterialFinder;
		
		Compare.bindEvents();
		ProductComparison.init();
	},

	"setCompareItems": function(items){
		Compare.state.productList = items;
		
	},

	"setURL": function(items){
		//console.log("items", items.join("--"));
		window.location.hash = "#compare?" + items.join("--");
	},

	"parseURL": function(){
		var items = []
		var url = window.location.hash.split("?")[1];
		var url = url.split("--");

		for (var v = 0; v < url.length; v++){
			var item = url[v].split("||");
			items.push({
				"id": item[0],
				"region": item[1]
			})
		}
		return items;
	},

	"bindEvents": function(){
		var CompareTray = Compare.parent.CompareTray;

		$('#app').on('change','[data-chart-selector]', function(e){
		
			var $sel =  $(this).find(':selected');
			var type = $sel.parent().attr('label');
			
			var subtype = $sel.text();
			Compare.state.activeChart = {
				"type": type,
				"subtype": subtype
			}

			Compare.renderCurveData();
		});

		$('#app').on('change', '[data-series-cb]', function(){
			Compare.updateChart();
		});

		$('#app').on('click', '[data-close-compare]', function(){
			Compare.parent.loadSavedSearch();
		});

		$('#app').on('click', '[data-compare-header]', function(){
			var value = $(this).attr('data-compare-header');
			$(this).toggleClass('show');
			$("[data-compare-list='"+value+"']").toggleClass('show');
		});

		$('#app').on('click', '[data-compare-remove]', function(){
			var items,
				removeId = $(this).attr('data-compare-remove');
			
			CompareTray.removeCompareItem(removeId);
			items = CompareTray.getCompareBasketList();
			Compare.setURL(items);
			Compare.render();
		});

		$('#app').on('change', '[data-comparable-toggle]', function(){
			Compare.settings.showComparableOnly = $(this).is(':checked');
			Compare.render();
		});

		$(window).on('scroll.sticky', function () {
			if (!$('#curveData').is(":visible")) {
				return
			}
			const curveDataTop = $('#curveData').offset().top,
				 scrollTop =  $(window).scrollTop()+(window.innerHeight/2);
			if (curveDataTop < scrollTop){
				$('#compareHeader').addClass('unstick')
			} else {
				$('#compareHeader').removeClass('unstick');
			}
		});	
		
	},

	"formatPropertyValue": function(property, isProduct) {
		if (!isProduct){
			return '<div class="emptycell nullcell">-</div>'; 
		} else if (property == undefined) {
			return '<div class="emptycell">-</div>';
		}

		var propVal = property.value ?? undefined, 
			value = "",
			unit = property.unit ?? undefined;
		
		if (unit == '-'){
			unit = '';
		}
		//no value, use max/min?
		if (typeof propVal == "undefined" || propVal == null) { value = property.min + " - " + property.max + " "+ unit} 
		else {
			//handle links?
			if (propVal.indexOf('http') == 0) { 
				value = '<a href="'+propVal+'" target="_blank" rel="noopener noreferrer">View</a>';
			
			} else {
				value = propVal + " "+ unit
			}
		}
		
		return '<div class="cell">'+ value +'</div>';
	},

	"render": function(){
		
		Compare.state.productList = Compare.parseURL();
		var ids = [];
		for (var v = 0; v < Compare.state.productList.length; v++){
			ids.push(Compare.state.productList[v].id);
		}

		api.getComparison(ids, function(data){
			ProductComparison.createMatrix(data, Compare.state.productList);
			var matrix = ProductComparison.getMatrix(Compare.settings.showComparableOnly);
			Compare.apiResponse = data;
			
			Compare.state.activeChart = Compare.findDefaultChart(data);
			
			Compare.parent.loadTemplate("Compare", window.api.template_path + "compare.html", function(template){
				$('#app-compare').html(ejs.render(template, {...Compare.parent.lang, "api": Compare.apiResponse, "formatPropertyValue": Compare.formatPropertyValue, "comparisonTitles": Object.keys(matrix), "comparisonData": matrix, ...Compare.state, "comparableOnly": Compare.settings.showComparableOnly}));
				Compare.renderCurveData();
				$('#app-compare').addClass('load');
			});

		});

	},

	"findDefaultChart": function(data){

		for (var v=0; v < data.length; v++){
			
			if (data[v].hasCurveData && data[v].curveData.length){
				return {
					"type":  data[v].curveData[0].type,
					"subtype": data[v].curveData[0].subTypes[0].title
				}
			}
		}

		return  {
			"type":  null,
			"subtype": null
		};
	},

	"renderCurveData": function(){
		Compare.state.curveData = Compare.processCurveData(Compare.apiResponse, Compare.state.activeChart.type, Compare.state.activeChart.subtype);

		if (Compare.state.curveData.seriesData.length > 0) {
			Compare.parent.loadTemplate("Compare_CurveData", window.api.template_path + "compare-curvedata.html", function (template) {
				$('#curveData').html(ejs.render(template, { ...Compare.parent.lang, "curveData": Compare.state.curveData, "activeChart":  Compare.state.activeChart.subtype  }));
				Compare.updateChart();
				$('[data-chart-selector]').select2({
					minimumResultsForSearch: -1
				});
				$('#curveData').show();

				$('body').removeClass('show-loading-modal');


			});
		} else {
			$('#curveData').hide();
        }
	
	},
	"getProductAsKey": function(list, key){
		var returnItem = null;
		list.map(function(el){
			if(el.title == key){
				returnItem = el
			} 
		});

		return returnItem;
	},

	"updateChart": function(){
		var selected = [];
		var dataset = [];
		var x = "";
		var y = "";

		//build a list of checked checboxes with their values
		$('[data-series-cb]:checked').each(function() {
			selected.push({
				"type":$(this).attr('data-series-type'),
				"subtype":$(this).attr('data-series-subtype'),
				"product":$(this).attr('data-product'),
				"dataset": $(this).val()
			});
		});

		if (selected.length == 0) {
			$('#chart').hide();
			return;
		} else {
			$('#chart').show();
			
			
			//loop through selected checkboxes and get the data feeds for each one
			for (var v = 0; v < selected.length; v++){
				var item = selected[v];
				var prod = Compare.getProductAsKey(Compare.state.curveData.dataset,item.product);
				
				if (prod !== null){
					var chartData = prod.data.getChartSeriesByLabel(item.type, item.subtype, item.dataset);
					var chartMeta = prod.data.getChartMetadata(item.type, item.subtype);

					var ds = {};
					var color = prod.data.getSabicColor(v);
					var seriesType = chartMeta.seriesType || "line"; //line or scatter

					x = chartData.x;
					y = chartData.y;
					var scaleX = chartMeta.scaleX || "linear"; //logarithmic
					var scaleY = chartMeta.scaleY || "linear"; //logarithmic
					var label = item.product;
					if (item.dataset !== '-'){
						label += ' - ' + item.dataset
					}
					ds = {
						type: seriesType,
						label: label,
						data: chartData.plotPoints,
						pointRadius: (seriesType == "line")?0:4
					};
					if (color!== undefined) {
						ds.backgroundColor =  color;
						ds.borderColor = color;
					}
					dataset.push(ds);
				}
			}
			//update the chart
			var chartInst = new Curvedata({}, $('#chart'));
			chartInst.drawChartFromDataset(dataset, x, y, scaleX, scaleY, seriesType, selected[0].type, selected[0].subtype );
		}
	},

	"getDataSeries": function(data, type,subtype){

		var seriesList = [];
		for (var v = 0; v < data.length; v++){
			if (data[v].data !== null){
				data[v].data.getSeriesList(type,subtype).map(function(dataEl){
					if (seriesList.indexOf(dataEl) == -1){
						seriesList.push(dataEl);
					}
				});
			}
		} 
		
		return seriesList;
	},

	"processCurveData": function(data, type, subtype){
		var dataset = [];
		var ids= [];
		
		//loop through curve data and adjust the json so its easier to work with
		for (var v = 0; v < data.length; v++){
			
			var cd = new CurveDataItem(data[v].curveData, $('#chart'));
			
			if (ids.indexOf(data[v].id) == -1){
				
				var ds = {
					title: data[v].title,
					data : (data[v].hasCurveData)?cd:null
				}
				ids.push(data[v].id);
				
				dataset.push(ds);
			}
		}
	
		return {
			"type": type,
			"subtype": subtype,
			"dropdownList": Compare.getDataSetList(data),
			"dataset": dataset,
			"seriesData":  Compare.getDataSeries(dataset,type,subtype)
			
		};
	},

	"getProductList": function(data){
		var products = [];

		data.map(function(el){
			products.push(el.title);
		});

		return products;
	},
/*
	"getCurveData": function(curvedata, reqCurve){
		var keys = Object.keys(curvedata);
		var i = keys.indexOf(reqCurve);

		if (i == -1) {return {}} else {return curvedata[i];}
	},
*/
	"getDataSetList": function(data) {

		//get a list of all chart series (used for populating the dropdown)
		var productList = Object.keys(data);
		var datasetList = {};
		var returnArray = [];
		//make a list of all active types and subtypes
		for (var v = 0; v < productList.length; v++){
			var prod = data[productList[v]];
			if (prod.hasCurveData){
				prod.curveData.map((el) => {
		
					if (datasetList[el.type] == undefined){
						datasetList[el.type] = {
							title: el.type
						}
						datasetList[el.type].subtypes = [];
					}
					
					el.subTypes.map((sel) => {
						if (datasetList[el.type].subtypes.indexOf(sel.title) == -1 ){
							datasetList[el.type].subtypes.push(sel.title)
						}
					});
				});
			}
		}

		//convert back to an array
		var typesList = Object.keys(datasetList);
		for (var v=0; v< typesList.length; v++){
			returnArray.push(datasetList[typesList[v]]);
		}
		
		return returnArray;
	}

}

export default Compare;