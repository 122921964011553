import autocolors from 'chartjs-plugin-autocolors';

let Curvedata = class {
	constructor(data, chart) {
		this.dataset = {}
		this.chartDiv = undefined;
		this.chart = undefined;
		this.sabicColors = ['#009FDF', '#FFA300', '#E35205','#FFCD00','#0047BB','#041E42','#E35205','#4D4D4D', '#C6C8CA'];

		if (data !== null) {
			Chart.register(autocolors);
			this.chartDiv = chart;
			for (var v = 0; v < data.length; v++) {
				this.dataset[data[v].type] = data[v];
			}
		}
	}

	getCurveDataTypes() {
		return Object.keys(this.dataset);
	}

	getCurveDataSubTypes(type) {
		var data = this.dataset[type];
		var subtypes = [];
		for (var v = 0; v < data.subTypes.length; v++) {
			subtypes.push({
				"title": data.subTypes[v].title,
				"id": data.subTypes[v].curveDataId
			});
		}
		return subtypes;
	}

	getCurveDataSubType(type, subtype) {
		var data = this.dataset[type];
		if (typeof data == "undefined") return {
			curveDataSets: {}
		};
		for (var v = 0; v < data.subTypes.length; v++) {
			if (subtype == data.subTypes[v].title) {
				return data.subTypes[v];
			}
		}
		return {};
    }

	getDataPoints(type,subtype){
		return this.getCurveDataSubType(type, subtype).curveDataSets||{};
	}

	getChartMetadata(type, subtype) {
		var data = this.getCurveDataSubType(type, subtype);
		//console.log(data);
		return {
			'seriesType': data.seriesType || 'line',
			'scaleX': data.scaleX  || 'linear',
			'scaleY': data.scaleY || 'linear'
        }
    }

	getChartSeries(type,subtype){
		var dataSet = this.getDataPoints(type, subtype);
		var meta = this.getChartMetadata(type, subtype);
		var series = [];
	
		for (var v = 0; v < dataSet.length; v++) {
			series.push(this.getSeries(v, subtype, dataSet[v], meta.seriesType));
		}
	
		return series;	
	}

	getChartSeriesByLabel(type,subtype,dsName){
		var dataSet = this.getDataPoints(type, subtype);
		if (dsName=="-"){
			dsName = null
		}	
		for (var v = 0; v < dataSet.length; v++) {

			if (dataSet[v].value == dsName){
				return dataSet[v];
			};

		}
	
		return null;	
	}

	getSabicColor(index){
		return this.sabicColors[index];
	}

	getSeriesList(type, subtype){
		var dataSet = this.getDataPoints(type, subtype);
		var series = [];

		for (var v = 0; v < dataSet.length; v++) {
			series.push(dataSet[v].value||"-");
		}

		return series;	
	}

	hasSeries(type,subtype,series){
		var seriesList = this.getSeriesList(type,subtype);
		return (seriesList.indexOf(series) > -1);
	}


	getSeries(index, title, dataset, seriesType) {
	
		var chartSeriesData = {
			"label": dataset.value || title,
			"data": dataset.plotPoints,
			"pointRadius": (seriesType == "line")?0:4
		}
		if (index < this.sabicColors.length){
			chartSeriesData.backgroundColor = this.sabicColors[index];
			chartSeriesData.borderColor = this.sabicColors[index];
		}
		return chartSeriesData;
	}

	getChartConfig(seriesData, dataset, scaleX, scaleY, seriesType, type, subtype, material) {
		
		material = material || "";
		
		return {
			type: seriesType,
			data: {
				datasets: seriesData
			},
			options: {
				layout: {
					padding: {
						left: 20,
						right: 40,
						top:20,
						bottom:20
					}
				},
				elements: {
					line: {
						tension: 0, // disables bezier curves
					}
				},
				plugins: {
					title: {
						display: true,
						text: material + type + ' - ' + subtype
					}
				},
				scales: {
					y: {
						type: scaleY,
						title: {
							display: true,
							text: dataset[0].y
						}
					},
					x: {
						type: scaleX,
						title: {
							display: true,
							text: dataset[0].x
						}
					}
				}
			}
		};
	}

	drawChart(type, subtype) {
		var seriesData = this.getChartSeries(type, subtype);
		var dataset = this.getDataPoints(type, subtype);
		var chartMeta = this.getChartMetadata(type, subtype);

		var $chart = $(this.chartDiv)[0].getContext('2d');
		var material = $(this.chartDiv).attr('data-chart-material') + " - ";
		
		var scaleX = chartMeta.scaleX;
		var scaleY = chartMeta.scaleY;
		var seriesType = chartMeta.seriesType;

		if (Chart.getChart($chart) !== undefined) {
			Chart.getChart($chart).destroy();
        }
		$('[data-chart-title]').attr('data-chart-title', material + type + ' - ' + subtype);
		const config = this.getChartConfig(seriesData, dataset, scaleX, scaleY, seriesType, type, subtype, material);

		this.chart = new Chart(
			$chart,
			config
		);

	}

	drawChartFromDataset(data, x, y, scaleX, scaleY, seriesType, type, subtype) {
		var $chart = $(this.chartDiv)[0].getContext('2d');

		if (Chart.getChart($chart) !== undefined) {
			Chart.getChart($chart).destroy();
        }
		$('[data-chart-title]').attr('data-chart-title',  '');
		const config = this.getChartConfig(data, [{ x: x, y: y }], scaleX, scaleY, seriesType, type, subtype);

		this.chart = new Chart(
			$chart,
			config
		);

	}
}

export default Curvedata;