

async function doPost(url = '', data = {}, method="POST"){
	let postData ={
		method: method, 
		cache: 'no-cache', 
		headers: {
			'Content-Type': 'application/json'
		},
		redirect: 'follow',  
		referrerPolicy: 'no-referrer'
	};

	if (data !== false){
		postData.body = JSON.stringify(data);
	}

	return fetch(url, postData);

}

async function postData(url = '', data = {}) {
	const response = await doPost(url, data);
	return response.json(); 
}

async function postDataHandler(url = '', data = {}){
	const response = await doPost(url, data);
	if (response.status == 200){
		return response.json(); 
	} else {
		return false;
	}
}


async function postDataStatus(url = '', data = {}) {
	const response = await doPost(url, data);
	return response.status;
}

async function getData(url = '') {
	const response = await doPost(url, false, "GET");
	return response.json(); 
}

const api = {
	"apiURLs": window.api,
	"getSearchResultsWithFilter": function (state, callback) {

		var searchParams = {
			"membershipId": null,
			"page": Number(state.page),
			"limit": 10,
			"searchOrder": state.order,
			"keywords": state.searchTerm,
			"Properties": [],
			"industries": [],
			"materialFeatures": [],
			"polymerFamilies": [],
			"fillers": [],
			"brands": [],
			"sustainables": []
		}

		if (typeof state.productId !== "undefined" && state.productId !== "null") {
			searchParams.productId = state.productId;
		}

		if (typeof state.productFamilyId !== "undefined" && state.productFamilyId !== "null") {
			searchParams.productFamilyId = state.productFamilyId;
		}
		//	console.log(state);

		if (typeof state.filters !== "undefined") {
			var filterKeys = Object.keys(state.filters);
			for (var v = 0; v < filterKeys.length; v++) {

				if (filterKeys[v].indexOf('properties_') == 0) {
					searchParams["Properties"].push({
						"PropertyFilter": Number(filterKeys[v].substring(11)),
						"Min": Number(state.filters[filterKeys[v]][0]),
						"Max": Number(state.filters[filterKeys[v]][1])
					})
				} else {
					if (typeof searchParams[filterKeys[v]] !== "undefined") {
						searchParams[filterKeys[v]] = searchParams[filterKeys[v]].concat(state.filters[filterKeys[v]]);
					}
				}
			}
		}

		postData(api.apiURLs.search, searchParams)
			.then(data => {
				callback(data);
			});

		
	},

	"getSearchKeywords": function(term, callback){
		
		//console.log("lookup partial keywords ", term);
		getData(api.apiURLs.keyword + term)
		.then(data => {
		
			callback(data);
		});
		
	},
	
	"addFavorite": function(id, callback){
		postDataHandler(api.apiURLs.addFavoriteGrade, {
			"gradeId" : id
		})
		.then(data => {
			if (!data) {
				window.location.reload();
			} else {
				callback(data);
			}
		});
		
	},
	
	"removeFavorite": function(id, callback){
		postDataHandler(api.apiURLs.removeFavoriteGrade, {
			"gradeId" : id
		})
		.then(data => {
			callback(data);
		});
		
	},

	"getFavoriteGrades": function(callback){
		getData(api.apiURLs.getFavouriteGrades)
		.then(data => {
			callback(data);
		});
		
	},


	"getComparison": function(ids, callback){
		var idParams = "?ids[]=" + ids.join("&ids[]=");
		//console.log("lookup comparisons ", idParams, ids.join(","));
		
		getData(api.apiURLs.compare + "/" + idParams )
		.then(data => {
			callback(data);

		});
	},

	"login": function (data, callback) {
		postData(api.apiURLs.login, data)
			.then(data => {
				callback(data);
			});
	},

	"register": function (data, callback) {
		postData(api.apiURLs.register, data)
			.then(data => {
				callback(data);
			});
	},

	"passwordReset": function (data, callback) {
		postDataStatus(api.apiURLs.resetPassword, data)
			.then(response_code => {
				callback(response_code);
			});
	},

	"changePassword": function (data, callback) {
		postData(api.apiURLs.changePassword, data)
			.then(data => {
				callback(data);
			});
	},

	"getDistributors": function (callback) {
		
		getData(api.apiURLs.distributors)
			.then(data => {
				callback(data);
			});
	},

	"memberVisit": function (data) {
		postData(api.apiURLs.logPageVisit, data);
	},

	"getIndustriesList": function (callback) {
		getData(api.apiURLs.industries)
			.then(data => {
				callback(data);
			});
	},

	"getInterestList": function (industry, callback) {
		getData(api.apiURLs.interests+industry)
			.then(data => {
				callback(data);
			});
	},

	"doApplicationSearch": function(data, callback){
		postData(api.apiURLs.applicationsSearch, data)
			.then(data => {
				callback(data);
			});
	}

}

export default api;