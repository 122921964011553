import autocomplete from 'autocompleter';
import api from '../api/api';

class DynamicSearchBox {
	constructor(el) {
		
		var input = el;
		
		this.handle = autocomplete({
			input: input,
			disableAutoSelect: true,
			debounceWaitMs: 100,
			/*emptyMsg:$(input).attr('data-noitems-msg'),*/
			fetch: function(text, update) {
				text = text.toLowerCase();
				$(input).addClass('loading');
				api.getSearchKeywords(text, function(data){
					update(data);
					$(input).removeClass('loading');
				});
				
			},
			onSelect: function(item) {
				input.value = item;
			},
			render: function(item, currentValue) {
				const itemElement = document.createElement("div");
				itemElement.textContent = item;
				return itemElement;
			}
		});
	}
	
	destroy() {
		this.handle.destroy();
	}
}

export default DynamicSearchBox;
