import $ from 'jquery';
import './libs/ejs.min.js';

import filterList from './views/filters';
import searchBar from './views/search-bar';
import homePage from './views/home';
import searchPage from './views/search';
import comparePage from './views/compare';
import compareTray from './views/compare-bar';
import DynamicSearchBox from './misc/autocomplete';
import URLParser from './misc/url-parser';
import Language from './misc/language';
import api from './api/api';

const MaterialFinder = { 
	"templates": {},
	"FilterList": filterList,
	"HomePage": homePage,
	"SearchPage": searchPage,
	"SearchBar": searchBar,
	"ComparePage": comparePage,
	"CompareTray": compareTray,
	"routes": {},
	
	"lang": {},
	"init": function(){
		
		Language.loadLanguage("en", function(lang){
			MaterialFinder.lang = { "lang":  lang };

			MaterialFinder.setRoutes();
			window.addEventListener('hashchange', function() {
				MaterialFinder.routeHandler();
			}, false);

			MaterialFinder.HomePage.init(MaterialFinder);
			MaterialFinder.SearchPage.init(MaterialFinder);
			
			MaterialFinder.CompareTray.init(lang);
			MaterialFinder.ComparePage.init(MaterialFinder);

			MaterialFinder.bindEvents();
			MaterialFinder.routeHandler();

			$('#app').trigger("init");
		});
	
		
	},

	"lazyLoadScript": function(id, url){
		let isLoaded = document.querySelectorAll('.ll-script-'+id);
		if(isLoaded.length > 0) {
			return;
		}
		
		let myScript = document.createElement("script");
		myScript.src = url;
		myScript.className = '.ll-script-'+id;
		document.body.appendChild(myScript);
	},

	"setRoutes": function(){
		MaterialFinder.routes =  {
			"default": {
				"class": "",
				"controller": MaterialFinder.HomePage
			},
			"search": {
				"class": "display-search-results",
				"controller": MaterialFinder.SearchPage
			},
			"compare": {
				"class": "display-compare",
				"controller": MaterialFinder.ComparePage
			}
		}
	},
	
	"initAutocompleteSearch": function () {
		
		$('[data-searchbox]').each(function (i, el) {
				
			if (typeof $(el).data('autocomplete') == "undefined") {
				$(el).data('autocomplete', new DynamicSearchBox(el));
			}
		});
	},
	"bindEvents": function(){


	},

	"loadTemplate": function(name, template, callback) {
		if (typeof MaterialFinder.templates[name] !== 'undefined') {
			callback(MaterialFinder.templates[name]);
			return;
		} else {

			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() {
				if (this.readyState == 4 && this.status == 200) {
					MaterialFinder.templates[name] = this.responseText;
					callback(this.responseText);
				}
			}      
			xhttp.open("GET", template, true);
			xhttp.send();
			return;
		}
	},

	"loadSavedSearch": function(){
		var savedState = JSON.parse(sessionStorage.getItem("savedSearch"));
		if (savedState !== undefined && savedState !== null){
			MaterialFinder.SearchPage.state = savedState;
		} else {
			MaterialFinder.SearchPage.initState();
		}
		$('#app').trigger("SearchParamsUpdated", false);	
	},

	"routeHandler": function(){
		var pageToShow = MaterialFinder.routes["default"];
		var params = URLParser.parseURL();
		var route = params[0];
		var state = params[1];
		
		if (route !== '' && typeof MaterialFinder.routes[route] !== "undefined"){
			pageToShow = MaterialFinder.routes[route];
		}

		if(typeof pageToShow !== 'undefined') {
			if (typeof pageToShow.controller !== "undefined"){
				pageToShow.controller.state = {...pageToShow.controller.state, ...state};
				pageToShow.controller.render();
			}
			$('#app').removeClass();
			$('#app').addClass(pageToShow.class);
		}

		
		if (window.member !== false) {
			api.memberVisit({
				url: window.location.pathname + window.location.hash
			});
		}
	}
}
if ($('#app.material-finder').length){
	MaterialFinder.init();
}