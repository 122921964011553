const ProductComparison = {
	products : [],
	settings : {
	},
	state: {
		"matrix": {}
	},
	

	init: function(){
		
	},

	getProductDataByRegion: function(products, region){
		for (var v = 0; v < products.propertyRegions.length; v++ ){
			if (products.propertyRegions[v].title == region) {
				return products.propertyRegions[v];
			}
		}	
		if (region !== "Global"){
			return ProductComparison.getProductDataByRegion(products,"Global");
		} else {
			return -1;
		}
	},

	createBlankArray: function(length) {
		return Array.apply(null, Array(length));
	},

	rewriteObjectToArray(obj) {
		var objKeys = Object.keys(obj), arr = [];
		for (var v = 0; v < objKeys.length; v++) {
			arr.push({
				title: objKeys[v],
				...obj[objKeys[v]]
			})
		} 
		return Arr;
	},

	removeIncomparable: function() {
		var cleaned = JSON.parse(JSON.stringify(ProductComparison.state.matrix)); 
		var productCategories = Object.keys(cleaned);

		//console.log(cleaned);

		for (var v = 0; v < productCategories.length; v++ ){
			var properties = Object.keys(cleaned[productCategories[v]]);
			
			for (var x = 0; x < properties.length; x++) {
				var prop = cleaned[productCategories[v]][properties[x]]; 
				if (prop.indexOf(undefined) > -1 || prop.indexOf(null) > -1){
					delete cleaned[productCategories[v]][properties[x]];
				}				
			}
		}

		return cleaned;

	},

	createMatrix: function(products, productList) {
		//parses the product structure and rewrites it into a format that we can use to compare
		//if products don't have an applicable property, matrix will return it as undefined
		ProductComparison.state.matrix = {};
		var res = {};

		//loop through products
		for (var v = 0; v < products.length; v++) {
	
			var productData = ProductComparison.getProductDataByRegion(products[v], productList[v].region);
			if (productData !== -1)  {

				//loop through property groups
				for (var x = 0; x < productData.propertyGroups.length; x ++ ) {
					var propertyGroup = productData.propertyGroups[x],
						propertyGroupTitle = propertyGroup.title;
					if (typeof res[propertyGroupTitle] == "undefined"){
						res[propertyGroupTitle] = {};
					}
					
					//loop through properties
					for (var z = 0; z < propertyGroup.properties.length; z++) {
						var property = propertyGroup.properties[z],
							propertyTitle = property.title;
					
						if (typeof res[propertyGroupTitle][propertyTitle] == "undefined"){
							//create an empty array of size of "products"
							res[propertyGroupTitle][propertyTitle] = ProductComparison.createBlankArray(products.length);
						}
						// push item to array
						res[propertyGroupTitle][propertyTitle][v] = property;
					}

				}
			}
		}
		ProductComparison.state.matrix = res;
	},

	getMatrix : function(returnComparable){
		if (returnComparable) {
			//console.log("remove incomparable");
			return ProductComparison.removeIncomparable();
		} else {
			return ProductComparison.state.matrix;
		}
	}

}

export default ProductComparison;