import api from '../api/api';
import URLParser from '../misc/url-parser';
import select2 from 'select2';
import Pagination from "../misc/pagination";

const SearchPage = {
	"settings": {
		"firstRender": true
	},
	"state": {
		"searchTerm": "",
		"filters": {},
		"open_filters": [],
		"page": 1,
		"productId": null,
		"productName": null,
		"productFamilyId": null,
		"productFamilyName": null,
		"order": "DESC"
	},
	"apiResponse": {},
	"parent": {},
	"init": function(MaterialFinder){
		SearchPage.initState();
		SearchPage.parent = MaterialFinder;
		SearchPage.bindEvents();
		MaterialFinder.FilterList.init(MaterialFinder);
		MaterialFinder.SearchBar.init(MaterialFinder);
	},

	"initState": function () {

		SearchPage.state = {
			"searchTerm": "",
			"filters": {},
			"open_filters": [],
			"page": 1,
			"productId": null,
			"productName": null,
			"productFamilyId": null,
			"productFamilyName": null,
			"order": "DESC"
		};
	},

	"bindEvents": function () {
		
		$('#app').on('click', '[data-search-page-next]', function(){
			SearchPage.state.page = Number(SearchPage.state.page) + 1;
			$('#app').trigger("SearchParamsUpdated", false);	
		});

		$('#app').on('click', '[data-search-page-prev]', function(){
			SearchPage.state.page = Math.max(1, Number(SearchPage.state.page) - 1);
			$('#app').trigger("SearchParamsUpdated", false);	
		});

		$('#app').on('click', '[data-set-page]', function(){
			const page = $(this).attr('data-set-page');
			SearchPage.state.page = Math.max(1, page);
			$('#app').trigger("SearchParamsUpdated", false);	
		});
		
		$('#app').on('select2:select', '[data-search-sort]', function(){
			SearchPage.state.order = this.value;
			$('#app').trigger("SearchParamsUpdated", true);
		});

		/* rerender if search completed */
		$('#app').on("SearchKeywordChange", function(){
			SearchPage.state.productId = null;
			SearchPage.state.productName = null;
			SearchPage.state.productFamilyId = null;
			SearchPage.state.productFamilyName = null;
			$('#app').trigger("SearchParamsUpdated", true);	
		});

		/* rerender if search completed */
		$('#app').on("SearchParamsUpdated", function(e, resetPage){
			if (resetPage){
				SearchPage.state.page = 1;
			}
			sessionStorage.setItem("savedSearch", JSON.stringify(SearchPage.state))
			URLParser.setURL('search', SearchPage.state);	
		});

		$('#app').on("SearchParamsCleared", function(){
			URLParser.setURL('', '');
			SearchPage.initState();
			SearchPage.apiResponse = {};
			$('#app').trigger("SearchParamsUpdated", true);	
		});

		$('#app').on("CompareUpdated", function (e, list) {
			console.log("UPDATE UI");
			SearchPage.updateUI();
		});

		$('#app').on('click', '[data-overflow-more]', function(){
			var $container = $(this).parents('.product');
			$container.removeClass('min');
		});
	},

	"render": function(){
		$('#app-search-results').addClass('loading');
		if (SearchPage.settings.firstRender){
			SearchPage.settings.firstRender = false;
		}
		
		api.getSearchResultsWithFilter(SearchPage.state, function(data){
			SearchPage.apiResponse = data;
			URLParser.setURL('search', SearchPage.state);
			//console.log("render search", {...SearchPage.parent.lang, "api": SearchPage.apiResponse, ...SearchPage.state});
			SearchPage.updateUI();
		});
		
	},
	"getSuggestedGrades": function(data,id){
		var suggested_grades = [];
		
		suggested_grades = data.slice(0, 4).filter((grade)=>{
			return grade.id !== id;
		}).slice(0, 3).map(a => a.id);
		return "?suggestedGradeIds[]="+ suggested_grades.join("&suggestedGradeIds[]=");
	},
	"updateUI": function(){
		const CompareTray = SearchPage.parent.CompareTray;
		const pagination = new Pagination(SearchPage.apiResponse.page,Math.ceil(SearchPage.apiResponse.total/SearchPage.apiResponse.limit)); 
		SearchPage.parent.loadTemplate("search", window.api.template_path + "search.html", function(template){
			$('#app-search-results').html(ejs.render(template, {...SearchPage.parent.lang, "pagination": pagination, "relatedGrades": SearchPage.getSuggestedGrades, "api": SearchPage.apiResponse, ...SearchPage.state, isInCompare : CompareTray.listContains, isCompareFull: CompareTray.isFull }));

			SearchPage.parent.FilterList.state = SearchPage.state;
			SearchPage.parent.FilterList.render();
			SearchPage.parent.SearchBar.state = SearchPage.state;
			SearchPage.parent.SearchBar.render();
			$('#app-search-results').removeClass('loading');

			const event = new Event('app:renderComplete');
			$('#app')[0].dispatchEvent(event);
			
			$('[data-search-sort]').select2({
				minimumResultsForSearch: -1
			});		

			$('.search-results-list .product').each(function(){
				const isOverflow = false;
				
				$(this).find('.overflow-list').each(function(){
					if ($(this).height() > 130) {
						$(this).parents('.product').addClass('min');
					}
				});
			
				
				
			})

		
		});
	}
}

export default SearchPage;