const Language = {
	"loadLanguage": function(language, callback) {
		let fileToLoad = "/dist/json/en.json";
		if (language == "en"){
			fileToLoad = "/dist/json/en.json";
		} else if (language == "ar"){
			fileToLoad = "/dist/json/ar.json";
		} 

		var xhttp = new XMLHttpRequest();
		xhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
			
				callback(JSON.parse(this.responseText));
			}      
		}
		xhttp.open("GET", fileToLoad, true);
		xhttp.send();
		return;
	}

	
}

export default Language;